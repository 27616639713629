.main {
  min-height: 100vh;

  > .content {
    padding: 50px 30px 0;
    position: relative;
    background: url('../../images/body-bg.png') no-repeat -265px -120px;

    .content-box {
      position: relative;
      padding: 20px 35px;
      margin-bottom: 30px;
      background: #232329;
      border-radius: 10px;

      @media only screen and (max-width: 1599px) {
        padding: 20px 25px;
      }

      @media only screen and (max-width: 1259px) {
        padding: 25px;
      }

      @media only screen and (max-width: 767px) {
        padding: 15px;
      }
    }
  }
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;

  &:hover {
    background: #555;
  }
}

.drag-bar {
  flex-shrink: 0;
  width: 5px;
  background-color: #2a2e39;
  cursor: col-resize;
  transition: background-color 0.15s 0.15s ease-in-out;

  &.dragging,
  &:hover {
    background-color: #63b3ed;
  }

  &.horizontal {
    height: 5px;
    width: 100%;
    cursor: row-resize;
  }
}

@media only screen and (max-width: 767px) {
  .main {
    > .content {
      padding: 15px 0;
      flex-wrap: wrap;
    }
  }
}
