.footer {
  position: relative;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  background: #212126;
  text-align: center;
  padding: 10px;

  .twitter-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 30px;
  }

  .copyright {
    margin: 0;
    font-size: 14px;
    color: #969ca2;
  }

  .feedback-button {
    height: 40px;
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #9a75e2;
    color: black;
    border-radius: 15px;
    border: none;

    &:hover {
      background-color: #804de8;
    }
  }

  .feedback-popup {
    background: white;
    padding: 10px;
    transform: translate(-20px, -20px);

    form {
      display: flex;
      flex-direction: column;

      label {
        color: black;
        margin-bottom: 15px;
      }

      textarea {
        height: 120px;
        min-width: 400px;
        resize: none;
        padding: 10px;
      }

      .feedback-submit-button {
        height: 40px;
        background-color: #9a75e2;
        color: black;
        border-radius: 15px;
        margin: 15px 0;
        border: none;

        &:hover {
          background-color: #804de8;
        }

        &:disabled {
          opacity: 0.3 !important;
        }
      }
    }
  }
}
