@media only screen and (max-width: 1599px) {
  body {
    font-size: 16px;
  }

  .market-cap-head-col {
    font-size: 14px;
  }

  .market-cap-head-col .market-cap-down {
    font-size: 12px;
  }

  .market-cap-head-col span.market-cap-rate {
    font-size: 18px;
  }

  .h2,
  h2 {
    font-size: 28px;
    font-weight: 600;
  }

  .over-btn-icon {
    width: 25px;
  }

  .over-tab-close .over-btn-icon:after {
    height: 25px;
    top: -11px;
    margin-left: -2px;
  }
}

@media only screen and (max-width: 1259px) {
  .h2,
  h2 {
    font-size: 30px;
    font-weight: 600;
  }

  .collection-table-box {
    overflow: auto;
  }

  .custom-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
    margin-top: 10px;
  }

  .h2,
  h2 {
    font-size: 24px;
    font-weight: 600;
  }
}

@media only screen and (max-width: 991px) {
  .sec {
    padding: 30px 0;
  }

  .custom-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33%;
    flex: 0 0 33.33%;
    max-width: 33.33%;
    margin-top: 10px;
  }

  .market-cap-box {
    margin-bottom: 15px;
  }

  .trad-period {
    font-size: 13px;
  }
}

@media only screen and (max-width: 767px) {
  ul.text-edit-list {
    overflow: auto;
  }

  .banner-sec h1 {
    font-size: 34px;
  }

  .banner-sec {
    padding: 95px 20px 30px;
  }

  h1 {
    font-size: 30px;
  }

  h4 {
    font-size: 18px;
  }

  body {
    font-size: 14px;
    line-height: 1.5;
  }

  .h2,
  h2 {
    font-size: 1.5rem;
  }

  .market-overview-col {
    width: 100%;
    padding: 0 15px;
    margin: 0 0 15px;
    text-align: center;
  }

  .market-overview-col.text-r {
    text-align: center;
  }

  ul.tab-list {
    text-align: center;
  }

  ul.text-edit-list li a {
    white-space: nowrap;
  }

  .custom-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 10px;
  }

  .pt-5,
  .py-5 {
    padding-top: 1.5rem !important;
  }
}
