body {
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
  color: #fff;
  background: #14141a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  line-height: 1.4;
  color: #fff;
}

.h2,
h2 {
  font-size: 32px !important;
  font-weight: 600;
}

button {
  outline: none;
  cursor: pointer;
  transition: 0.4s;

  &:focus {
    outline: none;
  }
}

a {
  text-decoration: none;
  color: #fff;
  transition: 0.4s;

  &:hover {
    text-decoration: none;
  }
}

.pointer {
  cursor: pointer;
}

.positive {
  color: #0dda0d;
}

.negative {
  color: red;
}
