@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

@import '~bootstrap/scss/bootstrap';
@import '~bootstrap/scss/bootstrap-utilities';
@import 'vendor/fontawesome';

@import 'components/common';
@import 'components/layout';
@import 'components/header';
@import 'components/footer';
@import 'components/responsive';
@import 'components/utilities';
@import 'components/custom';
@import 'components/collection';
@import 'components/analytics';
